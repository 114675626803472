import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { getCookie, setCookie } from './utils/cookie_funcs';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import { isMobile, isTablet } from 'react-device-detect';
import './App.css';
import './common.css';
import './index.css';
// import "./tailwind.css";
require('intersection-observer');
const bugsnagClient = bugsnag({
	apiKey: '03bcc3d2b6cef2e1004a80411912832c',
	notifyReleaseStages: ['production', 'staging'],
	appVersion: process.env.REACT_APP_VER,
	user: {
		student_uuid: typeof getCookie('student_uuid') !== 'undefined' ? getCookie('student_uuid') : null,
		student_name: typeof getCookie('student_name') !== 'undefined' ? getCookie('student_name') : null
	}
});
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const getUtmMedium = () => {
	return isMobile ? 'mobile_web_app' : isTablet ? 'tablet_web_app' : 'desktop_web';
};

export default class App extends Component {
	load_segment = () => {
		var analytics = (window.analytics = window.analytics || []);
		if (!analytics.initialize)
			if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
			else {
				analytics.invoked = !0;
				analytics.methods = [
					'trackSubmit',
					'trackClick',
					'trackLink',
					'trackForm',
					'pageview',
					'identify',
					'reset',
					'group',
					'track',
					'ready',
					'alias',
					'debug',
					'page',
					'once',
					'off',
					'on'
				];
				analytics.factory = function(t) {
					return function() {
						var e = Array.prototype.slice.call(arguments);
						e.unshift(t);
						analytics.push(e);
						return analytics;
					};
				};
				for (var t = 0; t < analytics.methods.length; t++) {
					var e = analytics.methods[t];
					analytics[e] = analytics.factory(e);
				}
				analytics.load = function(t, e) {
					var n = document.createElement('script');
					n.type = 'text/javascript';
					n.defer = true;
					n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
					var a = document.getElementsByTagName('script')[0];
					a.parentNode.insertBefore(n, a);
					analytics._loadOptions = e;
				};
				analytics.SNIPPET_VERSION = '4.1.0';
				analytics.load('Fv08ldrtMbe5Pp1dnxvIo8TmCityHvvO');
				analytics.page();
			}
	};
	handleLoad = () => {
		// try {
		// 	setTimeout(() => {
		// 		window.scrollTo(0, 1);
		// 	}, 0);
		// } catch {}
		setTimeout(() => {
			//this.load_segment();
			//this.loadMixPanel();
		}, 3000);
	};

	loadMixPanel = () => {
		//window.mixpanel.init('ef1b4a4e8c1cd36eb950d3ab05e70a57');
	};
	componentDidMount() {
		window.myt_seg_start_date = new Date().getTime();
		const urlString = window.location.href;
		const url = new URL(urlString);
		if (!!url.searchParams) {
			const utm_source = url.searchParams.get('utm_source');
			const utm_medium = url.searchParams.get('utm_medium');
			const utm_campaign = url.searchParams.get('utm_campaign');
			const utm_content = url.searchParams.get('utm_content');
			const utm_term = url.searchParams.get('utm_term');
			if (!!utm_source) {
				setCookie('myt_utm_source', utm_source, 86400);
			}

			setCookie('myt_utm_medium', getUtmMedium(), 86400);

			if (!!utm_campaign) {
				setCookie('myt_utm_campaign', utm_campaign, 86400);
			}
			if (!!utm_content) {
				setCookie('myt_utm_content', utm_content, 86400);
			}
			if (!!utm_term) {
				setCookie('myt_utm_term', utm_term, 86400);
			}
		}
		window.addEventListener('load', this.handleLoad);
	}
	componentWillUnmount() {
		window.removeEventListener('load', this.handleLoad);
	}
	render() {
		return (
			<ErrorBoundary>
				{/* <Provider store={store}> */}
				<Routes />
				{/* </Provider> */}
			</ErrorBoundary>
		);
	}
}
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
